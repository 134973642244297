<template>
    <v-dialog v-model="dialogVisible" max-width="400">
      <v-card>
        <v-card-title class="headline">Confirmação</v-card-title>
        <v-card-text>
          <div>
            <p>Tem certeza de que deseja sair da página? Os Resultados não serão Salvos.</p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red darken-1" text @click="cancel">Cancelar</v-btn>
          <v-btn color="green darken-1" @click="confirm" text>Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    data() {
      return {
        dialogVisible: false,
        confirmAction: null,
      };
    },
    methods: {
      showConfirmation(action) {
        this.confirmAction = action;
        this.dialogVisible = true;
      },
      confirm() {
        if (this.confirmAction) {
          this.confirmAction(); // This executes the action passed (navigation in our case)
        }
        this.dialogVisible = false;
      },
      cancel() {
        this.dialogVisible = false;
      },
    },
  };
  </script>
  
  <style>
  /* Add your custom styles for the dialog */
  </style>
  