<template>
    <v-container>
      <v-row class="mb-0" align="center">
  
        <v-col>
          <v-text-field
          class="mt-2"
            v-model="search"
            label="Buscar"
            dense
          ></v-text-field>
        </v-col>
        
      </v-row>
  
      <v-row class="mb-2" align="center">
        <!-- Botão para criar nova questão -->
        <v-btn
          color="primary"
          class="ml-4"
          @click="openCreateDialog(item)"
          >
          Criar Nova Questão
        </v-btn>
      </v-row>
  
      <!-- Tabela de dados -->
      <v-data-table
        :headers="headers"
        :items="filteredItems"
        item-value="id"
        class="elevation-1 fixed-table"
        editable
      >
        <!-- Custom slots para cada coluna com truncamento de texto -->
        <template v-slot:[`item.questao`]="{ item }">
          <span class="truncate-text">{{ item.questao }}</span>
        </template>
        <template v-slot:[`item.itemA`]="{ item }">
          <span class="truncate-text">{{ item.itemA }}</span>
        </template>
        <template v-slot:[`item.itemB`]="{ item }">
          <span class="truncate-text">{{ item.itemB }}</span>
        </template>
        <template v-slot:[`item.itemC`]="{ item }">
          <span class="truncate-text">{{ item.itemC }}</span>
        </template>
        <template v-slot:[`item.itemD`]="{ item }">
          <span class="truncate-text">{{ item.itemD }}</span>
        </template>
        <template v-slot:[`item.imagem`]="{ item }">
          <span class="truncate-text">{{ item.imagem }}</span>
        </template>
        <template v-slot:[`item.gabarito`]="{ item }">
          <span class="truncate-text">{{ item.gabarito }}</span>
        </template>
        <template v-slot:[`item.resolucao`]="{ item }">
          <span class="truncate-text">{{ item.resolucao }}</span>
        </template>
        <template v-slot:[`item.video`]="{ item }">
          <span class="truncate-text">{{ item.video }}</span>
        </template>
        <template v-slot:[`item.tema`]="{ item }">
          <span class="truncate-text">{{ item.tema }}</span>
        </template>
        <template v-slot:[`item.subTema`]="{ item }">
          <span class="truncate-text">{{ item.subTema }}</span>
        </template>
  
        <!-- Slot de ação para o botão de editar -->
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            @click="openEditDialog(item)"
            class ="mr-1"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            color="#B71C1C"
            @click="openDeleteDialog(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      
      <!-- Diálogo de edição -->
      <v-dialog v-model="createDialogVisible" max-width="800px">
        <v-card>
          <v-card-title class="mb-4">
            <span class="headline">Criar Questão</span>
          </v-card-title>
          <v-card-subtitle>
            Preencha os campos para criar uma nova questão. 
          </v-card-subtitle>
          <v-card-text>
            <v-form ref="form" v-model="formValid">
              <v-textarea
                v-model="editedItem.questao"
                label="Questão"
                :style="{ 'white-space': 'pre-line' }"
                rows="5" 
                outlined
                :rules="[v => !!v || 'Questão é obrigatória']"
              ></v-textarea>
              <v-textarea
                v-model="editedItem.itemA"
                label="Item A"
                :style="{ 'white-space': 'pre-line' }"
                rows="2"
                outlined
                :rules="[v => !!v || 'Item A é obrigatório']"
              ></v-textarea>
              <v-textarea
                v-model="editedItem.itemB"
                label="Item B"
                :style="{ 'white-space': 'pre-line' }"
                rows="2"
                outlined
                :rules="[v => !!v || 'Item B é obrigatório']"
              ></v-textarea>
              <v-textarea
                v-model="editedItem.itemC"
                label="Item C"
                :style="{ 'white-space': 'pre-line' }"
                rows="2"
                outlined
                :rules="[v => !!v || 'Item C é obrigatório']"
              ></v-textarea>
              <v-textarea
                v-model="editedItem.itemD"
                label="Item D"
                :style="{ 'white-space': 'pre-line' }"
                rows="2"
                outlined
                :rules="[v => !!v || 'Item D é obrigatório']"
              ></v-textarea>
  
              <!-- Substituir gabarito por radio buttons -->
              <v-radio-group v-model="selectedOption" label="Gabarito" row @change="updateGabarito" :rules="[v => !!v || 'Gabarito é obrigatório']">
                <v-radio label="A" value="A"></v-radio>
                <v-radio label="B" value="B"></v-radio>
                <v-radio label="C" value="C"></v-radio>
                <v-radio label="D" value="D"></v-radio>
              </v-radio-group>
  
              <v-text-field
                v-model="editedItem.imagem"
                label="Link da Imagem"
                outlined
              >
                <template v-slot:append>
                  <!-- Ícone de visualização -->
                  <v-icon
                    v-if="editedItem.imagem"
                    @click="showImageDialog"
                  >
                    mdi-eye
                  </v-icon>
                  <!-- Ícone de anexo -->
                  <v-icon
                    @click="selectImage"
                  >
                    mdi-attachment
                  </v-icon>
                </template>
              </v-text-field>
              <v-textarea
                v-model="editedItem.resolucao"
                label="Resolução"
                :style="{ 'white-space': 'pre-line' }"
                rows="5"
                outlined
              ></v-textarea>
              <v-text-field
                v-model="editedItem.video"
                label="Link do Vídeo (url)"
                outlined
                :append-icon="editedItem.video ? 'mdi-video' : ''"
                @click:append="showVideoDialog"
              />
              <v-autocomplete
                v-model="editedItem.tema"
                :items="uniqueThemes"
                label="Tema"
                outlined
                hide-details
                clearable
                return-object
                item-value="tema"
                item-text="tema"
                no-data-text="Digite para adicionar"
                @input="onInput"
                @update:search-input="onSearchInput"
                class="mb-5"
              ></v-autocomplete>
              <v-text-field
                v-model="editedItem.subTema"
                label="SubTema"
                outlined
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeEditDialog">Cancelar</v-btn>
            <v-btn color="primary" @click="saveCreate">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
  
      <!-- Diálogo de edição -->
      <v-dialog v-model="deleteDialogVisible" max-width="800px">
        <v-card>
          <v-card-title class="mb-4">
            <span class="headline">Deletar Questão - {{ editingItemId }}</span>
          </v-card-title>
          <v-card-subtitle>
            Você está prestes a deletar esta questão. Esta ação é irreversível e não será possível recuperar a questão depois de deletada. Deseja continuar?        </v-card-subtitle>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeEditDialog">Cancelar</v-btn>
            <v-btn color="#B71C1C" class="white--text" @click="deleteAction">Deletar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <!-- Diálogo de edição -->
      <v-dialog v-model="dialogVisible" max-width="800px">
        <v-card>
          <v-card-title class="mb-4">
            <span class="headline">Editar Questão - {{ editingItemId }}</span>
          </v-card-title>
          <v-card-subtitle>
            Preencha os campos para editar a questão desejada. 
          </v-card-subtitle>
          <v-card-text>
            <v-form>
              <v-textarea
                v-model="editedItem.questao"
                label="Questão"
                :style="{ 'white-space': 'pre-line' }"
                rows="5" 
                outlined
              ></v-textarea>
              <v-textarea
                v-model="editedItem.itemA"
                label="Item A"
                :style="{ 'white-space': 'pre-line' }"
                rows="2"
                outlined
              ></v-textarea>
              <v-textarea
                v-model="editedItem.itemB"
                label="Item B"
                :style="{ 'white-space': 'pre-line' }"
                rows="2"
                outlined
              ></v-textarea>
              <v-textarea
                v-model="editedItem.itemC"
                label="Item C"
                :style="{ 'white-space': 'pre-line' }"
                rows="2"
                outlined
              ></v-textarea>
              <v-textarea
                v-model="editedItem.itemD"
                label="Item D"
                :style="{ 'white-space': 'pre-line' }"
                rows="2"
                outlined
              ></v-textarea>
  
              <!-- Substituir gabarito por radio buttons -->
              <v-radio-group v-model="selectedOption" label="Gabarito" row>
                <v-radio label="A" :value="editedItem.itemA"></v-radio>
                <v-radio label="B" :value="editedItem.itemB"></v-radio>
                <v-radio label="C" :value="editedItem.itemC"></v-radio>
                <v-radio label="D" :value="editedItem.itemD"></v-radio>
              </v-radio-group>
  
              <v-text-field
                v-model="editedItem.imagem"
                label="Link da Imagem"
                outlined
              >
                <template v-slot:append>
                  <!-- Ícone de visualização -->
                  <v-icon
                    v-if="editedItem.imagem"
                    @click="showImageDialog"
                  >
                    mdi-eye
                  </v-icon>
                  <!-- Ícone de anexo -->
                  <v-icon
                    @click="selectImage"
                  >
                    mdi-attachment
                  </v-icon>
                </template>
              </v-text-field>
              <v-textarea
                v-model="editedItem.resolucao"
                label="Resolução"
                :style="{ 'white-space': 'pre-line' }"
                rows="5"
                outlined
              ></v-textarea>
              <v-text-field
                v-model="editedItem.video"
                label="Link do Vídeo (url)"
                outlined
                :append-icon="editedItem.video ? 'mdi-video' : ''"
                @click:append="showVideoDialog"
              />
              <v-autocomplete
                v-model="editedItem.tema"
                :items="uniqueThemes"
                label="Tema"
                outlined
                hide-details
                clearable
                return-object
                item-value="tema"
                item-text="tema"
                no-data-text="Digite para adicionar"
                @input="onInput"
                @update:search-input="onSearchInput"
                class="mb-5"
              ></v-autocomplete>
  
              <v-text-field
                v-model="editedItem.subTema"
                label="SubTema"
                outlined
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeEditDialog">Cancelar</v-btn>
            <v-btn color="primary" @click="saveEdit">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
          <!-- Novo Diálogo para upload de Video -->
  
      <v-dialog v-model="uploadDialogVisible" max-width="400px">
        <v-card>
          <v-card-title class="headline">Fazer Upload de Imagem</v-card-title>
          <v-card-text>
            <v-file-input
              v-model="imageFile"
              label="Selecione uma imagem"
              accept="image/*"
            ></v-file-input>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeUploadDialog">Cancelar</v-btn>
            <v-btn color="primary" @click="uploadImage">Upload</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <!-- Novo Diálogo para visualizar imagem -->
      <v-dialog v-model="imageDialogVisible" max-width="800px">
        <v-card>
          <v-card-title class="headline">Pré Visualização de Imagem</v-card-title>
          <v-card-text>
            <v-img :src="imageUrl" contain></v-img>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeImageDialog">Fechar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
      <!-- Novo Diálogo para visualizar Video -->
      <v-dialog v-model="videoDialogVisible" max-width="800px">
        <v-card>
          <v-card-title class="headline">Pré Visualização de Vídeo</v-card-title>
          <v-card-text>
            <v-responsive aspect-ratio="16/9">
              <iframe width="100%" height="315" :src="videoUrl" frameborder="0" allowfullscreen></iframe>
            </v-responsive>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeVideoDialog">Fechar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Novo Diálogo para a redefinição -->
      <v-dialog v-model="resetting" max-width="400" persistent>
          <v-card>
            <v-card-title>Salvando dados.</v-card-title>
            <v-card-text>
              <!-- Conteúdo do diálogo de redefinição -->
              Salvando alterações. Aguarde...
            </v-card-text>
          </v-card>
        </v-dialog>
    </v-container>
  </template>
  
  <script>
  import firebase from 'firebase/app';
  import 'firebase/storage';
  export default {
    data() {
      return {
        resetting: false,
        search: "", // Campo de busca
        dialogVisible: false, // Controle da visibilidade do diálogo
        deleteDialogVisible: false, // Controle da visibilidade do diálogo
        createDialogVisible:false,
        imageDialogVisible: false,
        uploadDialogVisible: false,
        videoDialogVisible: false,
        imageFile: null,
        imageUrl: "",
        videoUrl: "",
        editedItem: {
          tema: '' // valor inicial, pode ser alterado conforme necessário
        },
        // Lista de temas que pode ser atualizada conforme necessário
        themes: [], // Dados do item sendo editado
        editingItemId: null, // ID do item sendo editado
        selectedOption: "", // Armazena o valor do radio button selecionado
        headers: [
          { title: 'Ações', value: 'actions', sortable: false },
          { title: 'ID', value: 'id' },
          { title: 'Questão', value: 'questao' },
          { title: 'Item A', value: 'itemA' },
          { title: 'Item B', value: 'itemB' },
          { title: 'Item C', value: 'itemC' },
          { title: 'Item D', value: 'itemD' },
          { title: 'Imagem', value: 'imagem' },
          { title: 'Gabarito', value: 'gabarito' },
          { title: 'Resolução', value: 'resolucao' },
          { title: 'Vídeo', value: 'video' },
          { title: 'Tema', value: 'tema' },
          { title: 'SubTema', value: 'subTema' },
        ]
      };
    },
    computed: {
      loadedRawQuestions() {
        return this.$store.getters.loadedRawQuestions;
      },
      formattedItems() {
        return this.loadedRawQuestions.map(item => ({
          id: item.questionId,
          questao: item.question,
          itemA: item.questionOptions[0],
          itemB: item.questionOptions[1],
          itemC: item.questionOptions[2],
          itemD: item.questionOptions[3],
          imagem: item.questionImage,
          gabarito: item.questionAnswer,
          resolucao: item.questionComment,
          video: item.questionVideo,
          tema: item.questionTheme,
          subTema: item.questionSubTheme
        }));
      },
      uniqueThemes() {
        // Cria uma lista de temas únicos para o autocomplete
        return [...new Set(this.themes.concat(this.formattedItems.map(item => item.tema)))];
      },
      filteredItems() {
        if (!this.search) {
          return this.formattedItems;
        }
        const searchLower = this.normalizeString(this.search);
        return this.formattedItems.filter(item => {
          return Object.values(item).some(val =>
            this.normalizeString(String(val)).includes(searchLower)
          );
        });
      }
    },
    methods: {
      onSearchInput(value) {
        // Adiciona o valor digitado à lista de temas se não estiver presente
        if (value && !this.themes.includes(value)) {
          this.themes = [...this.themes, value];
        }
      },
        
  
      updateGabarito() {
        // Atualiza o campo gabarito com base no valor selecionado
        const selectedItem = this.selectedOption;
        if (selectedItem === 'A' ) {
          this.editedItem.gabarito = this.editedItem.itemA;
        } else if (selectedItem === 'B') {
          this.editedItem.gabarito = this.editedItem.itemB;
        } else if (selectedItem === 'C' ) {
          this.editedItem.gabarito = this.editedItem.itemC;
        } else if (selectedItem === 'D') {
          this.editedItem.gabarito = this.editedItem.itemD;
        } else {
          this.editedItem.gabarito = this.editedItem.itemD;
        }
      },
   
      showImageDialog() {
      this.imageUrl = this.editedItem.imagem;
      this.imageDialogVisible = true;
      },
      closeImageDialog() {
        this.imageDialogVisible = false;
        this.imageUrl = "";
      },
      showVideoDialog() {
        this.videoUrl = this.editedItem.video;
        this.videoDialogVisible = true;
      },
      closeVideoDialog() {
      this.videoDialogVisible = false;
      this.videoUrl = "";
    },
      closeUploadDialog() {
        this.uploadDialogVisible = false;
        this.imageFile = null;
      },
      async uploadImage() {
        if (!this.imageFile) {
          return;
        }
  
        const storageRef = firebase.storage().ref();
        const imageRef = storageRef.child(`images/${this.imageFile.name}`);
        try {
          await imageRef.put(this.imageFile);
          const imageUrl = await imageRef.getDownloadURL();
          this.editedItem.imagem = imageUrl;
          this.closeUploadDialog();
        } catch (error) {
          console.error("Erro ao fazer upload da imagem:", error);
        }
      },
      selectImage() {
        this.uploadDialogVisible = true;
      },
      closeUploadDialog() {
        this.uploadDialogVisible = false;
        this.imageFile = null;
      },
      normalizeString(str) {
        return str
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();
      },
      openDeleteDialog(item){
        this.editedItem = { ...item };
        this.editingItemId = item.id;
        this.deleteDialogVisible = true;
      },
      openCreateDialog(){
        this.editedItem = {
          id: null,
          questao: '',
          itemA: '',
          itemB: '',
          itemC: '',
          itemD: '',
          imagem: '',
          gabarito: '',
          resolucao: '',
          video: '',
          tema: '',
          subTema: ''
        };
        this.selectedOption = ""; // ou null
       
        this.createDialogVisible = true;
  
      },
      openEditDialog(item) {
        this.editedItem = { ...item };
        this.editingItemId = item.id;
  
        // Configurar o radio button correto com base no gabarito existente
        switch (item.gabarito) {
          case item.itemA:
            this.selectedOption = item.itemA;
            break;
          case item.itemB:
            this.selectedOption = item.itemB;
            break;
          case item.itemC:
            this.selectedOption = item.itemC;
            break;
          case item.itemD:
            this.selectedOption = item.itemD;
            break;
        }
  
        this.dialogVisible = true;
      },
      closeEditDialog() {
        this.dialogVisible = false;
        this.deleteDialogVisible = false;
        this.createDialogVisible = false;
        this.editingItemId = null;
        this.selectedOption = ""; // Limpar a seleção do radio button
      },
      deleteAction(){
        // Atualiza o gabarito com base na seleção do radio button
        this.resetting = true; 
        // Lógica para salvar as edições (ex.: enviar para a API, atualizar o Vuex, etc.)
        this.$store.dispatch('deleteEditedItem', this.editedItem)
          .then(() => {
            // Aguardar um tempo antes de recarregar os itens
            setTimeout(() => {
              this.$store.dispatch("loadRawQuestions");
            }, 1000); // 500 milissegundos de delay
            setTimeout(() => {
              this.resetting = false;
            }, 5000); // 500 milissegundos de delay
          })
          .catch(error => {
            console.error("Erro ao salvar item:", error);
          });
        this.closeEditDialog();
      },
  
      saveCreate() {
      // Valida o formulário antes de prosseguir
        this.$refs.form.validate();
        if (!this.formValid) {
          console.error("O formulário contém erros.");
          return;
        }
  
        console.log(this.editedItem);
        this.resetting = true; 
  
        // Lógica para salvar as edições (ex.: enviar para a API, atualizar o Vuex, etc.)
        this.$store.dispatch('createItem', this.editedItem)
          .then(() => {
            // Aguardar um tempo antes de recarregar os itens
            setTimeout(() => {
              this.$store.dispatch("loadRawQuestions")
                .finally(() => {
                  this.resetting = false; // Garantir que a redefinição ocorra após o carregamento dos itens
                });
            }, 1000); // 1000 milissegundos de delay para garantir que o salvamento seja concluído
          })
          .catch(error => {
            console.error("Erro ao salvar item:", error);
            this.resetting = false; // Garantir que a redefinição ocorra mesmo em caso de erro
          });
          this.themes = [];
  
        this.closeEditDialog();
      },
  
  
      saveEdit() {
      // Atualiza o gabarito com base na seleção do radio button
      this.editedItem.gabarito = this.selectedOption;
      this.resetting = true; 
      // Lógica para salvar as edições (ex.: enviar para a API, atualizar o Vuex, etc.)
      this.$store.dispatch('saveEditedItem', this.editedItem)
        .then(() => {
          // Aguardar um tempo antes de recarregar os itens
          setTimeout(() => {
            this.$store.dispatch("loadRawQuestions");
          }, 1000); // 500 milissegundos de delay
          setTimeout(() => {
            this.resetting = false;
          }, 5000); // 500 milissegundos de delay
        })
        .catch(error => {
          console.error("Erro ao salvar item:", error);
        });
        this.themes = [];
  
      this.closeEditDialog();
      }
  
  
    },
    created() {
          // Inicializa a lista de temas com os temas formatados
          this.themes = [...new Set(this.formattedItems.map(item => item.tema))];
        },
    beforeMount() {
      this.$store.dispatch("loadRawQuestions");
    }
  };
  </script>
  
  <style scoped>
  /* Estilo para truncar o texto */
  .truncate-text {
    display: inline-block;
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /* Estilo para centralizar os headers verticalmente */
  .fixed-table .v-data-table-header th {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Estilo para mover a flecha de ordenação para o lado direito */
  .fixed-table .v-data-table-header th .v-icon {
    margin-left: auto;
  }
  
  /* Estilo adicional para layout e espaçamento */
  .elevation-1 {
    margin-top: 20px;
  }
  </style>
  