<template>
  <div class="sobre">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="display-2">MedExperts App</h1>
          <p class="subheading" style="text-align: justify;">
            Informamos que o módulo de flashcards já está disponível como parte da nova atualização, trazendo também uma interface renovada para tornar a navegação ainda mais intuitiva. O módulo de flashcards está em constante desenvolvimento, e melhorias serão implementadas continuamente para aprimorar sua experiência.</p>

        </v-col>
      </v-row>
      <!-- Adicione o v-stepper aqui -->
      <v-stepper :items="['1° Passo', '2° Passo', '3° Passo']" class="mt-5" prev-text="Anterior" next-text="Próximo">
        <template v-slot:item.1>
          <v-card title="1° Passo" flat>1. Na aba Decks, navegue entre os Decks disponíveis. 
            <br>2. Clique no botão <v-icon>mdi-play</v-icon>para iniciar os estudos.</v-card> 
        </template>

        <template v-slot:item.2>
          <v-card title="2° Passo" flat>1. O primeiro Card é sempre uma pergunta ou informação. 
            <br>2. Clique em resposta para mostrar o outro lado que contém a resposta ou conceito correspondente.
            <br>3. Ao final, selecione o nível de dificuldade para esse card.  
          </v-card>
        </template>

        <template v-slot:item.3>
          <v-card title="3° Passo" flat>Revise os Cards constantemente, nosso algoritmo de repetição está em desenvolvimento e irá sempre mostrar o conteúdo mais relevante baseado no seu progresso.
            <br>
            Você também podem acompanhar seu progresso pela aba Métricas.

          </v-card>
        </template>
      </v-stepper>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Sobre',
};
</script>

<style scoped>
.sobre {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.sobre h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.sobre p {
  font-size: 1.2rem;
  color: #333;
}

/* Responsividade para dispositivos móveis */
@media screen and (max-width: 600px) {
  .sobre h1 {
    font-size: 2rem;
  }
  .sobre p {
    font-size: 1rem;
  }
}
</style>
