<template>

    <v-container>

      <!-- Loading Dialog -->
      <v-dialog v-model="loading" max-width="200" persistent>
            <v-card elevation="12" class="text-center" color="white">
                <v-card-text>
                <v-progress-circular indeterminate color="primary" size="20" class="pt-10" ></v-progress-circular>
                <div class="loading-text">Carregando...</div>
                </v-card-text>
            </v-card>
        </v-dialog>
      <!-- Include the ChagePage Dialog component -->
      <ConfirmationDialog ref="confirmDialog" />
      <!-- Adicione o diálogo para a sugestão Resetar Questoes -->
        <v-dialog v-model="showQuestionResetDialog" max-width="500">
          <v-card>
            <v-card-title>Resetar Questões</v-card-title>
            <v-card-text class="text-justify">
              <!-- Adicione a caixa de texto para a sugestão -->
              Ao resetar, o algoritmo de repetição será reiniciado. As questões já realizadas podem reaparecer. O Reset é recomendado para quem busca repetir questões de imediato. A contagem de questões completas permanecerá inalterada.
            </v-card-text>
            <v-card-actions>
              <!-- Adicione botões para salvar e fechar o diálogo -->
              <v-btn @click="saveQuestionReset" color="primary" text class="ml-auto">Resetar</v-btn>
              <v-btn @click="closeSuggestionDialog" color="red" text>Cancelar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Adicione o diálogo para a sugestão SUGESTAO DE QUESTAO -->
        <v-dialog v-model="showSuggestionDialog" max-width="500">
          <v-card>
            <v-card-title>Escreva sua sugestão</v-card-title>
            <v-card-text class="text-justify">
              <!-- Adicione a caixa de texto para a sugestão -->
              Se você encontrou algum problema nessa questão ou gostaria de sugerir alguma mudança, ficaríamos muito agradecidos por seu feedback.
              <v-textarea v-model="suggestionText" label="Caixa de Sugestão" rows="5" :rules="[v => !!v || 'Campo obrigatório']"></v-textarea>
            </v-card-text>
            <v-card-actions>
              <!-- Adicione botões para salvar e fechar o diálogo -->
              <v-btn @click="saveSuggestion" color="primary" text class="ml-auto" v-if="suggestionText !== ''" >Salvar</v-btn>
              <v-btn @click="closeSuggestionDialog" color="red" text>Fechar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      <!-- Novo Diálogo para a redefinição -->
        <v-dialog v-model="resetting" max-width="400" persistent>
          <v-card>
            <v-card-title>Salvando dados.</v-card-title>
            <v-card-text>
              <!-- Conteúdo do diálogo de redefinição -->
              Aguarde enquanto seus dados estão sendo salvos...
            </v-card-text>
          </v-card>
        </v-dialog>
      
      <!-- Add a form to select the number of questions -->
      <v-container v-if="quizstart !== true">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="6">
            <v-tooltip  right v-if="noqdName">
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  class="ma-2"
                  color="blue"
                  label
                  text-color="white"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon left>
                    mdi-label
                  </v-icon>
                  {{ String(noqdName) }} / {{ String(noqName) }} questões completas.
                </v-chip>
                <v-btn small icon v-bind="attrs" v-on="on" @click="openQuestionResetDialog" class="ml-auto mb-3">
                    <v-icon color="red">mdi-file-restore</v-icon>
                  </v-btn>
              </template>
              <span>Progresso de questões realizadas / disponíveis. Nota: Apenas questões após 31.08.2024 são computadas.</span>
            </v-tooltip>

            
            <v-card elevation="1">
              <v-card-title class="headline text-center font-weight-bold pl-10 pt-10 mb-0" style="color: #282828;white-space: normal; word-break: break-word; overflow-wrap: break-word;">Iniciar Questões {{ String(dbName) }}  </v-card-title>
              <!-- Numero de Questoes -->
              
              <v-text-field
                v-model.number="selectedNumberOfQuestions"
                label="Escolha o número de questões que gostaria de fazer (1-90)"
                type="number"
                min="1"
                max="90"
                dense
                class="pl-10 pr-10 pt-0 mx-auto"
              ></v-text-field>
              <!-- Dropdown para escolher o tema -->
              <v-select
                v-model="selectedTheme"
                :items="loadThemes"
                label="Escolha o(s) tema(s)"
                multiple
                style="width: 100%;"
                class="pl-10 pr-10 pt-3 mx-auto"
                @change="handleThemeSelectionChange"
                placeholder="Selecione um tema..."
                chips
                deletable-chips
                :menu-props="{ maxHeight: '300px', width: '300px' }"
              ></v-select>
              <!-- Dropdown para escolher o modo -->
              <v-select
                v-model="selectedMode"
                :items="['Tutor', 'Normal']"
                label="Escolha o modo"
                class="pl-10 pr-10 pt-3 mx-auto"
              ></v-select>
              <!-- Descrição do Modo de Operação -->
              <v-card-text v-if="selectedMode === 'Tutor'" class="pl-10 pr-10 pt-0 mt-1 mx-auto">
                No modo Tutor, as respostas e resoluções são exibidas logo após cada pergunta.
              </v-card-text>
              <v-card-text v-if="selectedMode === 'Normal'" class="pl-10 pr-10 pt-0 mt-1 mx-auto">
                No modo Normal, as respostas e resoluções ficam disponíveis apenas no final do simulado.
              </v-card-text>
              <!-- Botao de Iniciar -->
              <v-card-actions class="d-flex justify-center pb-5" elevation="0">
                <v-btn :disabled="selectedNumberOfQuestions > 90 || selectedNumberOfQuestions < 1 " 
                  style="width: 200px; height: 40px; font-size: 14px; color: white; border-radius: 10px; text-transform: none;" 
                  @click="startQuiz" 
                  color="primary"
                  elevation="0"
                  >Iniciar</v-btn>
                  
              </v-card-actions>
            </v-card>                
          </v-col>
        </v-row>
      </v-container>
  
      <!-- Dialog Comment with Video -->
      <v-dialog v-model="showCommentDrawer" max-width="800" v-if="quizstart" persistent>
        <v-card>
          <v-card-title class="headline">Resolução</v-card-title>
          <v-card-text>
            <div v-if="currentAnswer !== null" class="mb-4">
              <strong>Sua Resposta:</strong>
              <p>{{ currentAnswer }}</p>
            </div>
            <div v-if="currentQuestion.questionAnswer !== null" class="mb-4">
              <strong>Resposta Correta:</strong>
              <p><strong class="green--text text--darken-2">{{ currentQuestion.questionAnswer }}</strong></p>
            </div>
            <div v-if="currentQuestion.questionComment !== null" class="mb-4" :style="{ 'white-space': 'pre-line' }">
              <strong >Comentário:</strong>
              <p>{{ currentQuestion.questionComment || 'Estamos elaborando a resolução. Em breve, você poderá encontrá-la aqui.' }}</p>
            </div>
            <div v-if="currentQuestion.questionVideo" class="mb-4">
              <strong>Vídeo:</strong>
              <v-responsive>
                <iframe width="100%" height="315" :src="currentQuestion.questionVideo" frameborder="0" allowfullscreen></iframe>
              </v-responsive>
            </div>
          </v-card-text>
          <v-card-actions class="d-flex justify-end">
            <v-btn color="red" text @click="showCommentDrawer = false " >Fechar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  
  
      <!-- Questions-->
      <v-card v-if="currentQuestion !== null && quizstart !== false" class="question-card ">
        <v-card-title class="headline font-weight-bold">Questão {{ currentQuestionIndex + 1 }}
              <v-btn icon @click="openSuggestionDialog" class="ml-auto mb-3">
                <v-icon color="red">mdi-flag</v-icon>
              </v-btn>  
        </v-card-title>
        <v-chip  class="ml-5" color="teal" style="color: white; margin-right: 5px; padding-left: 10px;  border-radius: 8px;">{{ currentQuestion.questionTheme }}</v-chip>
        <!-- <v-chip class="ml-5" color="cyan " style="color: white; border-radius: 8px;" >{{ currentQuestion.questionSubTheme }}</v-chip></div> -->
        <v-card-text class="text-justify">
          <v-container>
            <v-row>
              <v-col cols="12">
                <div class="question-container">
                  <div class="question-text" :style="{ 'white-space': 'pre-line' }">
                    <h4>{{ currentQuestion.question }}</h4>
                    <v-col cols="12" class="d-flex justify-center" > 
                      <div v-if="currentQuestion.questionImage" class="image-container">
                        <img :src="currentQuestion.questionImage" alt="Question Image" class="question-image" />
                      </div>
                    </v-col>
                    <v-radio-group v-model="currentAnswer" :mandatory="true">
                      <v-radio
                      v-for="(option, optionIndex) in currentQuestion.questionOptions"
                      :key="optionIndex"
                      :label="option"
                      :value="option"
                      :disabled="questionLocked"
                      :class="{
                        'green-outline': isCorrectOption(option) && questionLocked && selectedMode === 'Tutor',
                        'red-outline': isWrongOption(option) && questionLocked && selectedMode === 'Tutor'
                      }"
                      ></v-radio>
                    </v-radio-group>             
                  </div>                
                </div>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-btn @click="goToPreviousQuestion" color="primary" v-if="currentQuestionIndex > 0">
              <span class="hidden-sm-and-up">&lt;</span> <!-- Displays "<" on smaller screens -->
              <span class="hidden-xs-only">Anterior</span> <!-- Displays "Anterior" on larger screens -->
            </v-btn>
            <v-btn class="ml-auto" @click="confirmQuestion" color="green" dark :disabled="currentAnswer === '' || currentAnswer === null" v-if="!questionLocked">
              Confirmar
            </v-btn>
            <v-btn @click="showCommentDrawer = true" color="secondary" v-if="questionLocked && selectedMode === 'Tutor'">
              Resolução
            </v-btn>
            <v-btn v-if="questionLocked" class="ml-auto" @click="quizCompleted ? resetTest() : (currentQuestionIndex + 1 === questions.length ? confirmLastQuestion() : nextQuestion())" color="primary" >
              <span class="hidden-sm-and-up">></span>
              <span class="hidden-xs-only">{{ quizCompleted ? 'Encerrar' : (currentQuestionIndex + 1 === questions.length ? 'Finalizar' : 'Avançar') }}</span>
            </v-btn>  
          </v-card-actions>
        </v-card-text>
  
        <!-- Progress Bar -->
        <v-row>
          <v-col cols="12">
            <v-progress-linear
              :value="calculateProgressPercentage"
              height="10"
              color="primary"
              background-color="grey lighten-3"
            ></v-progress-linear>
            <div class="text-center mt-2">{{ currentQuestionIndex + 1 }} de {{ questions.length }}</div>
          </v-col>
        </v-row>
      </v-card>
  
      <!-- Questions dialog finish-->
      <v-dialog v-if="quizCompleted" v-model="dialog" max-width="500" persistent>
        <v-card>
          <v-card-title class="headline">Resultados</v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-alert :value="true" type="success" outlined>
                    Respostas Corretas: {{ totalCorrectItems }}
                  </v-alert>
                  <v-alert :value="true" type="error" outlined>
                    Respostas Incorretas: {{ totalAnsweredQuestions - totalCorrectItems }}
                  </v-alert>
                  <v-alert :value="true" type="info" outlined>
                    Total de Questões Respondidas: {{ totalAnsweredQuestions }}
                  </v-alert>
                </v-col>
  
                    <!-- Seção para exibir as estatísticas por tema -->
                <v-container v-if="Object.keys(themeStats).length > 0">
                  <v-data-table :headers="tableHeaders" :items="getThemeStatsAsArray()" class="elevation-1" :mobile-breakpoint="0">
                    <template v-slot:item="{ item }">
                      <tr>
                        <td>{{ item.theme }}</td>
                        <td>{{ item.correctCount }}</td>
                        <td>{{ item.totalQuestions }}</td>
                      </tr>
                    </template>
                    <template v-slot:no-data>
                      <v-alert :value="true" color="info" outlined>
                        Nenhuma estatística disponível.
                      </v-alert>
                    </template>
                  </v-data-table>
                </v-container>
  
                <v-col cols="6" class="d-flex justify-center">
                  <v-progress-circular
                            :size="100"
                            :width="15"
                            :value= (totalCorrectItems/totalAnsweredQuestions)*100                    
                            color="success"
                            filled
                          >
                          <h3>{{((totalCorrectItems/totalAnsweredQuestions)*100).toFixed(1)}}%</h3>
                          <span style="
                              position: absolute;
                              bottom: -25px;
                              left: 50%;
                              transform: translateX(-50%);
                              font-size: 16px;
                              font-weight: bold;">
                            Acertos
                          </span>
                    </v-progress-circular>                  
                </v-col>
  
                <v-col cols="6" class="d-flex justify-center">
                  <v-progress-circular
                          :size="100"
                          :width="15"
                          :value= (incorrectCount/totalAnsweredQuestions)*100
                          color="red"
                          filled
                        >
                        <h3>{{((incorrectCount/totalAnsweredQuestions)*100).toFixed(1)}}%</h3>
                        <span style="
                            position: absolute;
                            bottom: -25px;
                            left: 50%;
                            transform: translateX(-50%);
                            font-size: 16px;
                            font-weight: bold;">
                          Erros
                        </span>
                  </v-progress-circular>
                </v-col>
              </v-row>
            </v-container>
           
          </v-card-text>
  
          <v-card-actions class="d-flex justify-center">
            <v-btn color="primary" @click="resetTest">Encerrar</v-btn>
            <v-btn color="success" @click="seeTest">Visualizar Respostas</v-btn>
          </v-card-actions>
  
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import ConfirmationDialog from '../components/Shared/ChangePage.vue';
  
  export default {
    components: {
      ConfirmationDialog,
    },
    data() {
      return {
        tableHeaders: [
        { text: 'Tema', value: 'theme' },
        { text: 'Acertos', value: 'correctCount' },
        { text: 'Total de Questões', value: 'totalQuestions' },
         ],
        availableQuestions: [1, 2, 3, 4, 5], // Define available question counts
        selectedNumberOfQuestions: null, // Store the selected number of questions
        showCommentDrawer: false,
        questionLocked: false,
        currentAnswer: '',
        quizStarted: false,
        selectedMode: 'Tutor',
        selectedTheme: ["Todos"],
        themeStats: {},
        currentQuestionIndex: 0,
        currentQuestion: null,
        currentAnswer: null,
        dialog: false,
        quizCompleted: false,
        quizstart: false,
        resetting: false,
        showSuggestionDialog: false,
        showQuestionResetDialog: false,
        suggestionText: '',
        answers: new Array(5),
        completedQuestionIds: [], // Array para armazenar os IDs das questões completadas
      };
    },
  
    beforeMount() {
      // Load data before  mounting
      localStorage.clear();
      document.cookie = "";
      this.$store.dispatch("loadItems");
    },
  
    computed: {
      totalAnsweredQuestions() {
      let total = 0;
      for (const theme in this.themeStats) {
        total += this.themeStats[theme].totalQuestions;
      }
      return total;
    },
  
    totalCorrectItems() {
      let total = 0;
      for (const theme in this.themeStats) {
        total += this.themeStats[theme].correctCount;
      }
      return total;
    },
    loading() {
            return this.$store.getters.loading;
        },
      loadItems: function () {
          return this.$store.getters.loadedItems;
        },
      dbName: function () {
          return this.$store.getters.dbName;
        },
      noqName: function () {
        return this.$store.getters.noqName;
      },
      noqdName: function () {
        return this.$store.getters.noqdName;
      },
      correctCount() {
        const filteredQuestions = this.loadItems.filter((question) => {
          if (this.selectedTheme.length === 0 || this.selectedTheme.includes('Todos')) {
            // Se 'Todos' ou nenhum tema específico selecionado, inclua todas as questões
            return true;
          } else {
            // Verifica se a questão possui pelo menos um dos temas selecionados
            return this.selectedTheme.includes(question.questionTheme);
          }
        });
  
        const filteredAnswers = this.answers.slice(0, filteredQuestions.length);
  
        return filteredAnswers.filter((answer, index) => {
          const correctAnswer = filteredQuestions[index].questionAnswer;
          return answer === correctAnswer || (this.selectedTheme.length === 0 && answer === undefined);
        }).length;
      },
  
    incorrectCount() {
      const filteredQuestions = this.loadItems.filter((question) => {
        if (this.selectedTheme.length === 0 || this.selectedTheme.includes('Todos')) {
          // Se 'Todos' ou nenhum tema específico selecionado, inclua todas as questões
          return true;
        } else {
          // Verifica se a questão possui pelo menos um dos temas selecionados
          return this.selectedTheme.includes(question.questionTheme);
        }
      });
  
      const filteredAnswers = this.answers.slice(0, filteredQuestions.length);
  
      return filteredAnswers.filter((answer, index) => {
        const correctAnswer = filteredQuestions[index].questionAnswer;
        return answer !== correctAnswer && (this.selectedTheme.length !== 0 || answer !== undefined);
      }).length;
    },
      answeredQuestions() {
      return this.answers.filter(answer => answer !== undefined).length;
      },
      loadThemes() {
        const themes = new Set(['Todos']);
        this.loadItems.forEach((question) => {
          // Verifica se question.questionTheme não está em branco antes de adicionar ao conjunto
          if (question.questionTheme !== '') {
            themes.add(question.questionTheme);
          }
        });
        return Array.from(themes);
      }
    },
  
  
    methods: {
      clearCookies() {
        const cookies = document.cookie.split(";");
        
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i];
          const eqPos = cookie.indexOf("=");
          const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
        }
  
        console.log("Cookies limpos!");
      },
  
      clearLocalStorage() {
        localStorage.clear();
        sessionStorage.clear();
        console.log("LocalStorage e SessionStorage limpos!");
      },
      openSuggestionDialog() {
        // Abre o diálogo de sugestão
        this.showSuggestionDialog = true;
      },
      openQuestionResetDialog() {
        // Abre o diálogo de sugestão
        this.showQuestionResetDialog = true;
      },
      saveQuestionReset() {
        this.resetting = true; 
        // Lógica para salvar as edições (ex.: enviar para a API, atualizar o Vuex, etc.)
        this.$store.dispatch('saveQuestionReset')
          .then(() => {
            // Aguardar um tempo antes de recarregar os itens
            setTimeout(() => {
              this.$store.dispatch("loadItems");
            }, 1000); // 500 milissegundos de delay
            setTimeout(() => {
              this.resetting = false;
            }, 5000); // 500 milissegundos de delay
          })
          .catch(error => {
            console.error("Erro ao salvar item:", error);
          });
        this.closeSuggestionDialog();
      },
      saveSuggestion() {
        // Implemente a lógica para salvar a sugestão, juntamente com as informações da questão atual
        // Pode incluir o envio para um servidor, armazenamento local, etc.
          const postSuggestion = {
            suggestionQuestion: this.currentQuestion.question,
            suggestionText: this.suggestionText
          };
          console.log(postSuggestion)
          this.$store.dispatch('postSuggestion', postSuggestion)
            .then(() => {
              console.log("Suggestion successful.");
              // Reload the window after successful submission
              setTimeout(() => {
                this.closeSuggestionDialog();
              }, 1000); // 2000 milliseconds = 2 seconds
            })
            .catch((error) => {
              console.error("Error submitting data:", error);
              // Handle error scenario here if needed
            });
     
      },
      closeSuggestionDialog() {
        // Fecha o diálogo de sugestão
        this.showSuggestionDialog = false;
        this.showQuestionResetDialog = false;
        // Limpa o texto da sugestão
        this.suggestionText = '';
      },
      getThemeStatsAsArray() {
      return Object.entries(this.themeStats).map(([theme, stats]) => ({
        theme,
        correctCount: stats.correctCount,
        totalQuestions: stats.totalQuestions,
      }));
    },  
    
      handleThemeSelectionChange() {
        // Se "Todos" estiver selecionado e qualquer outra opção for selecionada, desmarca "Todos"
        if (this.selectedTheme.includes('Todos') && this.selectedTheme.length > 1) {
          const todosIndex = this.selectedTheme.indexOf('Todos');
          this.selectedTheme.splice(todosIndex, 1);
        } 
        // Se todas as opções forem desmarcadas, seleciona "Todos"
        else if (this.selectedTheme.length === 0) {
          this.selectedTheme = ['Todos'];
        }
        
      },
    
      confirmTabChange(to, from, next) {
        if (this.quizStarted && from.path === '/webapp/home' && to.path !== '/webapp/home') {
          this.$refs.confirmDialog.showConfirmation(() => {
            // If confirmed, navigate to the requested route
            next();
          });
        } else {
          next(); // Continue with the route change if conditions aren't met
        }
      },
  
      confirmLastQuestion() {
        if (this.currentAnswer !== null) {
          this.answers.splice(this.currentQuestionIndex, 1, this.currentAnswer);
          this.questionLocked = true; // Lock the question
          this.quizCompleted = true;
          this.dialog = true; // Display results dialog
        }
      },
  
      seeTest() {
        console.log("clicando...")
        this.quizCompleted = false;
        this.selectedMode = 'Tutor';
        this.dialog = false; 
      },
  
      // Method to check if the option is the correct answer
      isCorrectOption(option) {
        return option === this.currentQuestion.questionAnswer;
      },
    // Method to check if the option is the selected wrong answer
      isWrongOption(option) {
        return option === this.currentAnswer && option !== this.currentQuestion.questionAnswer;
      },
      calculateProgressPercentage() {
         return ((this.currentQuestionIndex + 1) / this.questions.length) * 100;
      },
      
      goToPreviousQuestion() {
        if (this.currentQuestionIndex > 0) {
          this.currentQuestionIndex--;
          this.currentQuestion = this.questions[this.currentQuestionIndex];
          this.currentAnswer = this.answers[this.currentQuestionIndex];
          this.questionLocked = this.currentAnswer !== null;
        }
      },
  
      formatQuestionText(text) {
        // Replace \n with <br> tags in the question text
        return text.replace(/\n/g, '<br>');
      },
  
      validateNumberOfQuestions(value) {
        return value <= 90 || 'Número máximo de questões é 90';
      },
  
      startQuiz() {
        if (this.selectedNumberOfQuestions !== null) {
          // Filtra as questões com base nos temas selecionados
          const filteredQuestions = this.loadItems.filter((question) => {
            if (this.selectedTheme.length === 0 || this.selectedTheme.includes('Todos')) {
              // Se 'Todos' ou nenhum tema específico selecionado, inclua todas as questões
              return true;
            } else {
              // Verifica se a questão possui pelo menos um dos temas selecionados
              return this.selectedTheme.includes(question.questionTheme);
            }
          });
  
          // Gera o simulado com base nas questões filtradas
          const totalQuestions = Math.min(this.selectedNumberOfQuestions, filteredQuestions.length);
          this.questions = filteredQuestions.slice(0, totalQuestions);
  
          // Restante do código permanece o mesmo
          this.currentQuestion = this.questions[0];
          this.answers = new Array(totalQuestions);
          this.quizCompleted = false;
          this.dialog = false;
          this.currentQuestionIndex = 0;
          this.quizstart = true;
          this.quizStarted = true;
        }
      },
      confirmQuestion() {
        if (this.currentAnswer !== null) {
          this.completedQuestionIds.push(this.currentQuestion.questionId); // Adiciona o ID da questão atual ao array
  
          const currentTheme = this.currentQuestion.questionTheme;
          // Inicializa as estatísticas do tema, se ainda não existirem
          if (!this.themeStats[currentTheme]) {
            this.themeStats[currentTheme] = {
                totalQuestions: 0,
              correctCount: 0,
              incorrectCount: 0,
            };
          }
  
          // Atualiza as estatísticas do tema
          this.themeStats[currentTheme].totalQuestions++;
          if (this.isCorrectOption(this.currentAnswer)) {
            this.themeStats[currentTheme].correctCount++;
          } else {
            this.themeStats[currentTheme].incorrectCount++;
          }
  
          // Restante do código permanece o mesmo
          this.answers.splice(this.currentQuestionIndex, 1, this.currentAnswer);
          this.questionLocked = true; // Bloqueia a pergunta
          // Mostra o commentDrawer apenas se selectedMode for 'Tutor'
          if (this.selectedMode === 'Tutor') {
            this.showCommentDrawer = true; // Mostra a resolução
          }
        }
      },
  
      nextQuestion() {
        if (this.currentAnswer !== null) {
          if (this.currentQuestionIndex < this.questions.length - 1) {
  //          this.completedQuestionIds.push(this.questions[this.currentQuestionIndex].questionId); // Adiciona o ID da questão atual ao array
  
            const nextQuestionIndex = this.currentQuestionIndex + 1;
            const nextAnswer = this.answers[nextQuestionIndex];
  
            this.currentQuestionIndex = nextQuestionIndex;
            this.currentQuestion = this.questions[nextQuestionIndex];
            this.currentAnswer = nextAnswer !== undefined ? nextAnswer : null;
            this.questionLocked = this.currentAnswer !== null;
          } else {
            this.quizCompleted = true;
            this.dialog = true;
          }
        }
    },
      resetTest() {
        console.log("IDs das questões realizadas:", this.completedQuestionIds);
  
        this.resetting = true; 
        this.quizStarted = false
        const themeStatsArray = Object.entries(this.themeStats).map(([theme, stats]) => ({
          theme,
          totalQuestions: stats.totalQuestions,
          correctCount: stats.correctCount,
          incorrectCount: stats.incorrectCount,
        }));
        const themeStatsJson = JSON.stringify(themeStatsArray);
        const questionIdsJson = JSON.stringify(this.completedQuestionIds);
  
        const postData = {
          lastQuestions: this.totalAnsweredQuestions,
          lastRight: this.totalCorrectItems,
          lastWrong: this.totalAnsweredQuestions - this.totalCorrectItems,
          themeStats: themeStatsJson,
          questionIds :questionIdsJson, // Inclui estatísticas por tema
        };
        this.$store.dispatch('postItems', postData)
          .then(() => {
            console.log("Submission successful. Reloading...");
            // Reload the window after successful submission
            setTimeout(() => {
              this.$router.push('/webapp/QuestionsResults');
              this.resetting = false;
            }, 5000); // 2000 milliseconds = 2 seconds
          })
          .catch((error) => {
            console.error("Error submitting data:", error);
            // Handle error scenario here if needed
          });
  
          console.log("Submitting...");
          // Reset quiz data (excluding window reload)
          this.currentQuestionIndex = 0;
          this.currentQuestion = this.questions[0];
          this.currentAnswer = null;
          this.quizCompleted = false;
          this.answers = new Array(5);
          this.dialog = false;
          this.quizstart = false;
      }    
    },
  
    beforeRouteLeave(to, from, next) {
      this.confirmTabChange(to, from, next);
    },
  
    mounted() {
      this.clearCookies(); // Limpar cookies quando o componente for montado
      this.clearLocalStorage(); // Limpar localStorage e sessionStorage
      //this.currentQuestion = this.questions[this.currentQuestionIndex];
      // Força o carregamento sem cache
      fetch(window.location.href, { cache: "no-store" })
        .then(() => {
          console.log("Página carregada sem cache.");
          this.currentQuestion = this.questions[this.currentQuestionIndex];
        })
        .catch(error => {
          console.error("Erro ao recarregar sem cache:", error);
        });
    }
  };
  </script>
  
  <style>
  /* CSS */
  .green-outline {
    -webkit-text-stroke: 0.5px green; /* Green outline for correct options */
    text-stroke: 1px green;
    color: transparent;
  }
  
  .red-outline {
    -webkit-text-stroke: 0.5px red; /* Red outline for wrong options */
    text-stroke: 1px red;
    color: transparent;
  }
  
  .loading-text {
    margin-top: 15px;
    font-weight: bold;
    font-size: 18px;
  }
  .question-card {
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .question-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  
  .image-container {
    margin-left: 20px;
    margin-top: 10px;
    text-align: center;
  }
  
  .question-image {
    max-width: 60%;
    height: auto;
    display: block;
  }
  
  .question-text {
    flex: 1;
    margin-bottom: 10px;
  }
  
  .v-btn {
    margin-top: 10px;
  }
  
  .text-justify {
    text-align: justify;
  }
  
  .justify-end {
    justify-content: flex-end;
  }
  </style>