<template>
  <v-main :style="$vuetify.theme.dark ? 'background-color: #121212;' : 'background-color: rgb(106 147 196);'" >
    
    <v-container class="fill-height" fluid>
      
      <v-row no-gutters align="center" justify="center">
        <v-col sm="12" md="8" lg="6" xl="4">
          
          <div v-if="error">
            <app-alert @dismissed="onDismissed" :error="error.message" ></app-alert>
            <!-- Use v-if diretamente no v-alert -->
            <!-- Use v-if diretamente no v-alert -->
            <v-alert v-if="error" :value="true"  @click="onDismissed" type="error">
            {{ error.message }}
          </v-alert>
          </div>
          <v-card class="px-auto px-6 py-8 rounded-xl">
            <v-img
              :width="320"
              src="../assets/logo-0.png"
              class="mx-auto mb-8"
            ></v-img>
            <v-form

              v-model="form"
              @submit.prevent="onSubmit"
            >
              <v-text-field
                v-model="email"
                :readonly="loading"
                :rules="[rules.required, rules.email]"
                class="mt-4 mb-2"
                clearable
                label="Insira seu Email"
                prepend-inner-icon="mdi-account-outline"
                style="font-family: 'Inter', sans-serif;"
              ></v-text-field>
              <v-text-field
                v-if="!forgotPassword"
                v-model="password"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :readonly="loading"
                :rules="[rules.required, rules.min]"
                :type="show ? 'text' : 'password'"
                clearable
                label="Insira sua Senha"
                placeholder="Digite sua senha"
                @click:append="show = !show"
                prepend-inner-icon="mdi-lock-outline"
              ></v-text-field>

              <v-btn
                v-if="!forgotPassword"
                block
                justify="center"
                text
                small
                @click="showForgotPassword"
                class="d-flex justify-center"
                style="text-transform: none; text-decoration: underline;" 
              >
                Alterar ou redefinir sua senha
              </v-btn>
              
              <v-btn
                v-if="!forgotPassword"
                :disabled="!form"
                :loading="loading"
                block
                color="#3474be"
                style="height: 48px; font-size: 15px; color: white; border-radius: 10px" 
                type="submit"
                variant="elevated"
                text-color="white"
                rounded
              >
                Entrar
              </v-btn>
            </v-form>
            <v-btn
              v-if="showForgot"
              block
              :disabled="!form"
              color="#3474be"
              style="height: 48px; font-size: 15px; color: white; border-radius: 10px" 
              @click="confirmForgotPassword"
              rounded
            >
              Confirmar
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <ConfirmationDialog :dialogVisible="showConfirmationDialog" @close="closeConfirmationDialog" :email="email" />

  </v-main>

</template>

<script>
import ConfirmationDialog from '../components/Shared/PasswordReset.vue';

export default {
  components: {
    ConfirmationDialog,
  },
  data: () => ({
    email: "",
    password: "",
    form: false,
    show: false,
    showConfirmationDialog: false,

    rules: {
      required: value => !!value || 'Campo Obrigátorio.',
      min: v => v.length >= 6 || 'Sua senha deve ter no mínimo 6 caracteres.',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'E-mail Inválido.'
      },
    },
    forgotPassword: false,
    showForgot: false,
  }),

  methods: {
    onSubmit() {
      if (!this.form) return;

      let payload = {
        email: this.email,
        password: this.password,
      };
      this.$store.dispatch('signUserIn', payload);
    },
    onDismissed() {
      this.$store.dispatch('clearError');
    },
    showForgotPassword() {
      this.forgotPassword = true;
      this.showForgot = true;
    },
    confirmForgotPassword() {
      this.$store.dispatch('forgotPassword', {email: this.email})
          
      // For example, sending an email or redirecting to a new page
      this.showConfirmationDialog = true;

      // Then reset the fields and show the original form
     // this.email = '';
      this.password = '';
      this.forgotPassword = false;
      this.showForgot = false;
    },
    closeConfirmationDialog() {
      // Close the confirmation dialog
      this.showConfirmationDialog = false;
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
  },
  watch: {
    user(value) {
      if (value !== null && value !== undefined) {
        if (value.email) {
          let userName = value.email.split('@')[0];
          if (userName == "eventcenter") {
            this.$router.push('/webapp/event');
          } else {
            this.$router.push('/webapp/questions');
          }
        } else {
          this.$router.push('/webapp/questions');
        }
      }
    },
  },
};
</script>

<style>

</style>
