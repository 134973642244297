<template>

  <v-container>
    <!-- Include the ChagePage Dialog component -->
    <ConfirmationDialog ref="confirmDialog" />
      <!-- Adicione o diálogo para a sugestão SUGESTAO DE QUESTAO -->
      <v-dialog v-model="showSuggestionDialog" max-width="500">
        <v-card>
          <v-card-title>Escreva sua sugestão</v-card-title>
          <v-card-text class="text-justify">
            <!-- Adicione a caixa de texto para a sugestão -->
            Se você encontrou algum problema nessa questão ou gostaria de sugerir alguma mudança, ficaríamos muito agradecidos por seu feedback.
            <v-textarea v-model="suggestionText" label="Caixa de Sugestão" rows="5" :rules="[v => !!v || 'Campo obrigatório']"></v-textarea>
          </v-card-text>
          <v-card-actions>
            <!-- Adicione botões para salvar e fechar o diálogo -->
            <v-btn @click="saveSuggestion" color="primary" text class="ml-auto" v-if="suggestionText !== ''" >Salvar</v-btn>
            <v-btn @click="closeSuggestionDialog" color="red" text>Fechar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <!-- Novo Diálogo para a redefinição -->
      <v-dialog v-model="resetting" max-width="400" persistent>
        <v-card>
          <v-card-title>Salvando dados.</v-card-title>
          <v-card-text>
            <!-- Conteúdo do diálogo de redefinição -->
            Aguarde enquanto seus dados estão sendo salvos...
          </v-card-text>
        </v-card>
      </v-dialog>
    
    <!-- Add a form to select the number of questions -->
    <v-container v-if="quizstart !== true">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6">
          <v-card elevation="1">
            <v-card-title class="headline text-center font-weight-bold pl-10 pt-10 mb-0" style="color: #282828; white-space: normal; word-break: break-word; overflow-wrap: break-word;">Gabarito dos Simulados {{ String(dbName) }} </v-card-title>
            <!-- Numero de Questoes -->
             <!-- Descrição do Modo de Operação -->
            <v-card-text v-if="selectedMode === 'Tutor'" class="pl-10 pr-10 pt-0 mt-1 mx-auto">
            Encontre o gabarito comentado desejado a partir da Data do Simulado.
            </v-card-text>
            <!-- Dropdown para escolher o tema -->
            <v-select
              v-model="selectedTheme"
              :items="loadThemes"
              label="Escolha o Simulado"
              
              class="pl-10 pr-10 pt-3 mx-auto"
              placeholder="Escolha o Simulado."
              chips
              deletable-chips
            ></v-select>
            <!-- Botao de Iniciar -->
            <v-card-actions class="d-flex justify-center pb-5" elevation="0">
              <v-btn :disabled="!selectedTheme"
                style="width: 200px; height: 40px; font-size: 14px; color: white; border-radius: 10px; text-transform: none;" 
                @click="startQuiz" 
                color="primary"
                elevation="0"
                >Conferir Gabarito</v-btn>
            </v-card-actions>
          </v-card>                
        </v-col>
      </v-row>
    </v-container>

    <!-- Dialog Comment with Video -->
    <v-dialog v-model="showCommentDrawer" max-width="800" v-if="quizstart" persistent>
      <v-card>
        <v-card-title class="headline">Resolução</v-card-title>
        <v-card-text>
          <div v-if="currentQuestion.questionAnswer !== null" class="mb-4">
            <strong>Resposta Correta:</strong>
            <p><strong class="green--text text--darken-2">{{ currentQuestion.questionAnswer }}</strong></p>
          </div>
          <div v-if="currentQuestion.questionComment !== null" class="mb-4" :style="{ 'white-space': 'pre-line' }">
            <strong >Comentário:</strong>
            <p>{{ currentQuestion.questionComment || 'Estamos elaborando a resolução. Em breve, você poderá encontrá-la aqui.' }}</p>
          </div>
          <div v-if="currentQuestion.questionVideo" class="mb-4">
            <strong>Vídeo:</strong>
            <v-responsive>
              <iframe width="100%" height="315" :src="currentQuestion.questionVideo" frameborder="0" allowfullscreen></iframe>
            </v-responsive>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="red" text @click="showCommentDrawer = false " >Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


 
    <!-- Questions-->
    <v-card v-if="currentQuestion !== null && quizstart !== false" class="question-card ">
      <v-row class="justify-LEFT ">
      <v-select
      class="ml-5 mt-5"
        v-model="currentQuestionIndexInput"
        :items="questionNumbers"
        style="max-width: 300px;"
        label="Selecione a Questão"
        dense
        outlined
      ></v-select></v-row>
      <!-- Progress Bar -->
      <v-card-title class="headline font-weight-bold">Questão {{ currentQuestionIndex + 1 }}
            <v-btn icon small @click="openSuggestionDialog" class="ml-5 mb-3">
              <v-icon color="red">mdi-flag</v-icon>
            </v-btn>  
      </v-card-title>
      <!-- <v-chip  class="ml-5" color="teal" style="color: white; margin-right: 5px; padding-left: 10px;  border-radius: 8px;">{{ currentQuestion.questionTheme }}</v-chip> -->
      <v-card-text class="text-justify">
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="question-container">
                <div class="question-text" :style="{ 'white-space': 'pre-line' }">
                  <h4>{{ currentQuestion.question }}</h4>
                  <v-col cols="12" class="d-flex justify-center" > 
                    <div v-if="currentQuestion.questionImage" class="image-container">
                      <img :src="currentQuestion.questionImage" alt="Question Image" class="question-image" />
                    </div>
                  </v-col>
                  <v-radio-group v-model="currentAnswer" :mandatory="true">
                    <v-radio
                    v-for="(option, optionIndex) in currentQuestion.questionOptions"
                    :key="optionIndex"
                    :label="option"
                    :value="option"
                    :disabled="questionLocked"
                    :class="{
                      'green-outline': isCorrectOption(option) && questionLocked && selectedMode === 'Tutor',
                      'red-outline': isWrongOption(option) && questionLocked && selectedMode === 'Tutor'
                    }"
                    ></v-radio>
                  </v-radio-group>             
                </div>                
              </div>
            </v-col>
          </v-row>
          <v-card>
            <v-card-title class="headline">Resolução</v-card-title>
            <v-card-text>
              <div v-if="currentQuestion.questionAnswer !== null" class="mb-4">
                <strong>Resposta Correta:</strong>
                <p><strong class="green--text text--darken-2">{{ currentQuestion.questionAnswer }}</strong></p>
              </div>
              <div v-if="currentQuestion.questionComment !== null" class="mb-4" :style="{ 'white-space': 'pre-line' }">
                <strong >Comentário:</strong>
                <p>{{ currentQuestion.questionComment || 'Estamos elaborando a resolução. Em breve, você poderá encontrá-la aqui.' }}</p>
              </div>
              <div v-if="currentQuestion.questionVideo" class="mb-4">
                <strong>Vídeo:</strong>
                <v-responsive>
                  <iframe width="100%" height="512" :src="currentQuestion.questionVideo" frameborder="0" allowfullscreen></iframe>
                </v-responsive>
              </div>
            </v-card-text>
      </v-card>
        </v-container>
        <v-card-actions>
          <v-btn @click="goToPreviousQuestion" color="primary" v-if="currentQuestionIndex > 0">
            <span class="hidden-sm-and-up">&lt;</span> <!-- Displays "<" on smaller screens -->
            <span class="hidden-xs-only">Anterior</span> <!-- Displays "Anterior" on larger screens -->
          </v-btn>
          <v-btn class="ml-auto" @click="confirmQuestion" color="green" dark :disabled="currentAnswer === '' || currentAnswer === null" v-if="!questionLocked">
            Confirmar
          </v-btn>
          <!-- <v-btn @click="showCommentDrawer = true" color="secondary" v-if="questionLocked && selectedMode === 'Tutor'">
            Resolução
          </v-btn> -->
          <v-btn v-if="questionLocked" class="ml-auto" @click="quizCompleted ? resetTest() : (currentQuestionIndex + 1 === questions.length ? confirmLastQuestion() : nextQuestion())" color="primary" >
            <span class="hidden-sm-and-up">></span>
            <span class="hidden-xs-only">{{ quizCompleted ? 'Encerrar' : (currentQuestionIndex + 1 === questions.length ? 'Finalizar' : 'Avançar') }}</span>
          </v-btn>  
        </v-card-actions>
      </v-card-text>

      <!-- Progress Bar -->
      <v-row>
        <v-col cols="12">

          <v-row class="justify-center ">
            <v-text-field
            v-model.number="currentQuestionIndexInput"
            style="max-width: 50px;"
            label=""
            type="number"
            min="1"
            :max="questions.length"
            dense
            outlined
            @blur="updateCurrentQuestion"
            class="no-arrows"
          ></v-text-field>
          <div class="text-center mt-2">  &nbsp; de {{ questions.length }}</div></v-row>
          
        </v-col>
        
      </v-row>

      
    </v-card>

    <!-- Questions dialog finish-->
    <v-dialog v-if="quizCompleted" v-model="dialog" max-width="500" persistent>
      <v-card>
        <v-card-title class="headline">Deseja sair do Gabarito?</v-card-title>


        <v-card-actions class="d-flex justify-center">
          <v-btn color="primary" @click="resetTest">Encerrar</v-btn>
          <v-btn color="success" @click="seeTest">Continuar visualizando</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ConfirmationDialog from '../components/Shared/ChangePage.vue';

export default {
  components: {
    ConfirmationDialog,
  },
  data() {
    return {
      tableHeaders: [
      { text: 'Tema', value: 'theme' },
      { text: 'Acertos', value: 'correctCount' },
      { text: 'Total de Questões', value: 'totalQuestions' },
       ],
      availableQuestions: [1, 2, 3, 4, 5], // Define available question counts
      selectedNumberOfQuestions: 5, // Store the selected number of questions
      showCommentDrawer: false,
      questionLocked: true,
      currentAnswer: '',
      quizStarted: false,
      selectedMode: 'Tutor',
      selectedTheme: '', // Inicializa como uma string vazia
      themeStats: {},
      currentQuestionIndex: 0,
      currentQuestion: null,
      currentAnswer: null,
      dialog: false,
      quizCompleted: false,
      quizstart: false,
      resetting: false,
      showSuggestionDialog: false,
      suggestionText: '',
      answers: new Array(5),
      currentQuestionIndexInput: 1, // Inicia com o índice atual + 1

    };
    
  },
  watch: {
  currentQuestionIndex(newVal) {
    this.currentQuestionIndexInput = newVal + 1; // Mantém o input sincronizado com o índice atual
  },
  currentQuestionIndexInput(newVal) {
    this.updateCurrentQuestion(newVal);
  }
},

  beforeMount() {
    // Load data before  mounting
    //this.$store.dispatch("loadItems");
    this.$store.dispatch("loadGabarito");
  },

  computed: {
    totalAnsweredQuestions() {
    let total = 0;
    for (const theme in this.themeStats) {
      total += this.themeStats[theme].totalQuestions;
    }
    return total;
  },

  totalCorrectItems() {
    let total = 0;
    for (const theme in this.themeStats) {
      total += this.themeStats[theme].correctCount;
    }
    return total;
  },
    loading() {
        return this.$store.getters.loading;
      },
    // loadItems: function () {
    //     return this.$store.getters.loadedItems;
    //   },
      loadGabarito: function () {
        return this.$store.getters.loadedGabarito;
      }, 
      dbName: function () {
          return this.$store.getters.dbName;
       },

    answeredQuestions() {
    return this.answers.filter(answer => answer !== undefined).length;
    },
    loadThemes() {
      const themes = new Set([]);
      this.loadGabarito.forEach((question) => {
        // Verifica se question.questionTheme não está em branco antes de adicionar ao conjunto
        if (question.simuladoData !== '') {
          themes.add(question.simuladoData);
        }
      });
      return Array.from(themes);
    },
    questionNumbers() {
    return Array.from({ length: this.questions.length }, (_, i) => i + 1);
  }
  },


  methods: {
    updateCurrentQuestion() {
    // Verifica se o valor inserido é válido e atualiza a questão
    if (this.currentQuestionIndexInput >= 1 && this.currentQuestionIndexInput <= this.questions.length) {
      this.currentQuestionIndex = this.currentQuestionIndexInput - 1;
      this.currentQuestion = this.questions[this.currentQuestionIndex];
      this.currentAnswer = this.questions[this.currentQuestionIndex].questionAnswer;
      this.questionLocked = this.currentAnswer !== null;
    } else {
      // Reseta o input para o valor correto se o input for inválido
      this.currentQuestionIndexInput = this.currentQuestionIndex + 1;
    }
  },
    openSuggestionDialog() {
      // Abre o diálogo de sugestão
      this.showSuggestionDialog = true;
    },
    saveSuggestion() {
      // Implemente a lógica para salvar a sugestão, juntamente com as informações da questão atual
      // Pode incluir o envio para um servidor, armazenamento local, etc.
        const postSuggestion = {
          suggestionQuestion: this.currentQuestion.question,
          suggestionText: this.suggestionText
        };
        console.log(postSuggestion)
        this.$store.dispatch('postSuggestion', postSuggestion)
          .then(() => {
            console.log("Suggestion successful.");
            // Reload the window after successful submission
            setTimeout(() => {
              this.closeSuggestionDialog();
            }, 1000); // 2000 milliseconds = 2 seconds
          })
          .catch((error) => {
            console.error("Error submitting data:", error);
            // Handle error scenario here if needed
          });
   
    },
    closeSuggestionDialog() {
      // Fecha o diálogo de sugestão
      this.showSuggestionDialog = false;
      // Limpa o texto da sugestão
      this.suggestionText = '';
    },

  
    confirmTabChange(to, from, next) {
      if (this.quizStarted && from.path === '/webapp/home' && to.path !== '/webapp/home') {
        this.$refs.confirmDialog.showConfirmation(() => {
          // If confirmed, navigate to the requested route
          next();
        });
      } else {
        next(); // Continue with the route change if conditions aren't met
      }
    },

    confirmLastQuestion() {
      if (this.currentAnswer !== null) {
        this.answers.splice(this.currentQuestionIndex, 1, this.currentAnswer);
        this.questionLocked = true; // Lock the question
        this.quizCompleted = true;
        this.dialog = true; // Display results dialog
      }
    },

    seeTest() {
      console.log("clicando...")
      this.quizCompleted = false;
      this.selectedMode = 'Tutor';
      this.dialog = false; 
    },

    // Method to check if the option is the correct answer
    isCorrectOption(option) {
      return option === this.currentQuestion.questionAnswer;
    },
  // Method to check if the option is the selected wrong answer
    isWrongOption(option) {
      return option === this.currentAnswer && option !== this.currentQuestion.questionAnswer;
    },
    calculateProgressPercentage() {
       return ((this.currentQuestionIndex + 1) / this.questions.length) * 100;
    },
    
    goToPreviousQuestion() {
      if (this.currentQuestionIndex > 0) {
        this.currentQuestionIndex--;
        this.currentQuestion = this.questions[this.currentQuestionIndex];
        this.currentAnswer = this.questions[this.currentQuestionIndex].questionAnswer;  // Marca a opção correta
        this.questionLocked = this.currentAnswer !== null;
      }
    },

    formatQuestionText(text) {
      // Replace \n with <br> tags in the question text
      return text.replace(/\n/g, '<br>');
    },


    startQuiz() {
      if (this.selectedNumberOfQuestions !== null) {
        // Filtra as questões com base nos temas selecionados
        const filteredQuestions = this.loadGabarito.filter((question) => {
          if (this.selectedTheme.length === 0 || this.selectedTheme.includes('Todos')) {
            // Se 'Todos' ou nenhum tema específico selecionado, inclua todas as questões
            return false;
          } else {
            // Verifica se a questão possui pelo menos um dos temas selecionados
            return question.simuladoData && this.selectedTheme.includes(question.simuladoData);
          }
        });

        // Gera o simulado com base nas questões filtradas
        const totalQuestions = Math.min(filteredQuestions.length);
        this.questions = filteredQuestions.slice(0, totalQuestions);

        // Restante do código permanece o mesmo
        this.currentAnswer = this.questions[0].questionAnswer;  // Marca a opção correta
        this.currentQuestion = this.questions[0];
        this.answers = new Array(totalQuestions);
        this.quizCompleted = false;
        this.dialog = false;
        this.currentQuestionIndex = 0;
        this.quizstart = true;
        this.quizStarted = true;
      }
    },
    confirmQuestion() {
      if (this.currentAnswer !== null) {
          // Restante do código permanece o mesmo
        this.answers.splice(this.currentQuestionIndex, 1, this.currentAnswer);
        this.questionLocked = true; // Bloqueia a pergunta
        // Mostra o commentDrawer apenas se selectedMode for 'Tutor'
        if (this.selectedMode === 'Tutor') {
          this.showCommentDrawer = true; // Mostra a resolução
        }
      }
    },

    nextQuestion() {
      if (this.currentAnswer !== null) {
        if (this.currentQuestionIndex < this.questions.length - 1) {
          const nextQuestionIndex = this.currentQuestionIndex + 1;
          // const nextAnswer = this.answers[nextQuestionIndex];

          this.currentQuestionIndex = nextQuestionIndex;
          this.currentQuestion = this.questions[nextQuestionIndex];
          this.currentAnswer = this.questions[nextQuestionIndex].questionAnswer;  // Marca a opção correta

          //this.currentAnswer = nextAnswer !== undefined ? nextAnswer : null;
          this.questionLocked = this.currentAnswer !== null;
        } else {
          this.quizCompleted = true;
          this.dialog = true;
        }
      }
  },
    resetTest() {
      this.resetting = false; 
      this.quizStarted = false
        // Reset quiz data (excluding window reload)
        this.currentQuestionIndex = 0;
        this.currentQuestion = this.questions[0];
        this.currentAnswer = null;
        this.quizCompleted = false;
        this.answers = new Array(5);
        this.dialog = false;
        this.quizstart = false;
        this.questionLocked = false;
    }    
  },

  beforeRouteLeave(to, from, next) {
    this.confirmTabChange(to, from, next);
  },

  mounted() {
    if (Array.isArray(this.questions) && this.questions.length > 0 && this.currentQuestionIndex >= 0) {
    this.currentQuestion = this.questions[this.currentQuestionIndex];
  } else {
    console.error('Questions array is empty or index is invalid.');
  }  }
};
</script>

<style>
/* CSS */
.no-arrows input[type="number"]::-webkit-outer-spin-button,
.no-arrows input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-arrows input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.green-outline {
  -webkit-text-stroke: 0.5px green; /* Green outline for correct options */
  text-stroke: 1px green;
  color: transparent;
}

.red-outline {
  -webkit-text-stroke: 0.5px red; /* Red outline for wrong options */
  text-stroke: 1px red;
  color: transparent;
}

.loading-text {
  margin-top: 15px;
  font-weight: bold;
  font-size: 18px;
}
.question-card {
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.question-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.image-container {
  margin-left: 20px;
  margin-top: 10px;
  text-align: center;
}

.question-image {
  max-width: 60%;
  height: auto;
  display: block;
}

.question-text {
  flex: 1;
  margin-bottom: 10px;
}

.v-btn {
  margin-top: 10px;
}

.text-justify {
  text-align: justify;
}

.justify-end {
  justify-content: flex-end;
}
</style>